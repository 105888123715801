import React from 'react';
import 'CSS/Forum/PostingContextMenu.css';

export default function PostingContextMenu(props) {

	const [update, setUpdate] = React.useState(Date.now());
	const [showFavoritAdd, setShowFavoritAdd] = React.useState(['d-none']);
	const [showFavoritRemove, setShowFavoritRemove] = React.useState(['d-none']);

	const wad = props.wa.dialog;
	const waf = props.wa.form;
	const wam = props.wa.module;
	const was = props.wa.system;

	wad[props.dialogId].init = init;

	function init(postingNo, visible, memberId) {
		/*
		setShowTrashFill('d-none');
		setShowTrash('d-none');
		if (was.isAdmin()) {
			if (visible === 1) {
				setShowTrash('d-inline');
			}
			else {
				setShowTrashFill('d-inline');
			}
		}
		*/
		was.axGet('Backend/webapp.php?module=Posting&task=Favorit&tasktype=get&postingTable=postings&postingNo=' + postingNo, handleGet);

	}

	function handleGet(result) {
		if (result.data.content['status'] === 1) {
			setShowFavoritRemove('d-inline');
			setShowFavoritAdd('d-none');
		}
		else {
			setShowFavoritAdd('d-inline');
			setShowFavoritRemove('d-none');
		}
	}

	function postingDelete(buttonText) {
		wad['yesno'].setTitle('Beitrag ' + buttonText + '?')
		wad['yesno'].onClose = handleDelete;
		wad['yesno'].show();

	}

	function handleDelete(answer) {
		if (typeof answer === 'undefined') {
			answer = 0;
		}
		if (answer === 1) {
			let postingNo = props.answerTo;
			was.axGet('/Backend/webapp.php?module=Posting&task=HidePosting&postingTable=postings&postingNo=' + postingNo, handleHide);
			wad[props.dialogId].hide();
		}
	}

	function handleHide(result) {
		// wam.get('forumGetPostings', props.moduleId)();
		wam[props.moduleId].postings.load();
	}

	function favoritSet() {
		let postingNo = props.answerTo;
		was.axGet('/Backend/webapp.php?module=Posting&task=Favorit&tasktype=set&postingTable=postings&postingNo=' + postingNo, handleSet);

	}

	function handleSet(result) {
		if (result.data.content['status'] === 1) {
			setShowFavoritRemove('d-inline');
			setShowFavoritAdd('d-none');
		}
	}

	function favoritRemove() {
		let postingNo = props.answerTo;
		was.axGet('/Backend/webapp.php?module=Posting&task=Favorit&tasktype=remove&postingTable=postings&postingNo=' + postingNo, handleRemove);

	}

	function handleRemove(result) {
		if (result.data.content['status'] === 1) {
			setShowFavoritAdd('d-inline');
			setShowFavoritRemove('d-none');
		}
	}

	function editPosting() {
		props.editPosting();
		setUpdate(Date.now());
		waf.focus(props.dialogId, 'base-posting');
	}

	function postingSaveEdit() {
		props.postingSaveEdit();
		setUpdate(Date.now());	
	}	

	function postingCancelEdit() {
		props.postingCancelEdit();
		setUpdate(Date.now());	
	}

	return (
		<div className='posting-context-menu '>
			<span className="d-none">{update}</span>
			<span className="context-menu-title">{'Ausgewählter Beitrag (' + props.answerTo + '):'}</span>
			{
				((waf.isField(props.dialogId, 'base-posting') && waf.isReadOnly(props.dialogId, 'base-posting')) && (wad[props.dialogId].memberId === was.memberId || was.isAdmin())) 
					? 
						<i className={`bi bi-pencil context-menu-icon `} title="Diesen Beitrag bearbeiten" onClick={e => editPosting()}></i>
					:
						null
			}

			{
				(waf.isField(props.dialogId, 'base-posting') && !waf.isReadOnly(props.dialogId, 'base-posting'))
					? 
						<React.Fragment>
							<i className="bi bi-check-circle context-menu-icon" title="Änderungen speichern" onClick={e => postingSaveEdit()}></i>
							<i className="bi bi-x-circle context-menu-icon" title="Änderungen verwerfen" onClick={e => postingCancelEdit()}></i>
						</React.Fragment>
					:
					null
			}
			<i className={`bi bi-star context-menu-icon ${showFavoritAdd}`} title="Zu Favoriten hinzufügen" onClick={e => favoritSet()}></i>
			<i className={`bi bi-star-fill context-menu-icon ${showFavoritRemove}`} title="Favorit enfernen" onClick={e => favoritRemove()}></i>
			<i 	className={`bi bi-quote context-menu-icon`} 
				title="Diesen Beitrag in einem neuen Thema zitieren" 
				onClick={e => props.postingQuote(e, wad[props.dialogId].posting, wad[props.dialogId].postingDate, wad[props.dialogId].memberName)}></i>
			{
				(was.isAdmin() && wad[props.dialogId].visible)
				?
					<i className={`bi bi-trash3 context-menu-icon `} title="Löschen" onClick={e => postingDelete('löschen')}></i>
				:
					null
			}
			{
				(was.isAdmin() && !wad[props.dialogId].visible)
				?
					<i className={`bi bi-trash3-fill context-menu-icon `} title="Wiederherstellen" onClick={e => postingDelete('wiederherstellen')}></i>
				:
					null
			}
			<i
				className="bi bi-copy"
				title="Beitrag in die Zwischenablage kopieren"
				onClick={e => props.copyToClipboard(e, wad[props.dialogId].posting, wad[props.dialogId].postingDate, wad[props.dialogId].memberName)}>
			</i>
		</div>
	)

}