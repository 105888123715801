import React from 'react';

export default function FormElement(props) {

	const toolBar = (typeof props.toolBar === 'undefined') ? null : props.toolBar;
	const buttons = (typeof props.buttons === 'undefined') ? null : props.buttons;
	const reference = React.useRef(null);

	const waf = props.wa.form;

	const disabled = (typeof props.disabled == 'undefined') ? false : props.disabled;
	const readOnly = (typeof props.readOnly == 'undefined') ? false : props.readOnly;

	const inputType = (typeof props.type === 'undefined') ? 'text' : props.type;
	const classes = (typeof props.classes === 'undefined') ? null : props.classes;
	const children = (typeof props.children === 'undefined') ? null : props.children;
	const onChange = (typeof props.onChange === 'undefined') ? () => {} : props.onChange;
	const onFocus = (typeof props.onFocus === 'undefined') ? () => {} : props.onFocus;
	const onPaste = (typeof props.onPaste === 'undefined') ? () => {} : props.onPaste;

	React.useEffect(() => {
		props.wa.form.initField(
			props.wafId,
			props.id,
			reference,
			(typeof props.default === 'undefined') ? '' : props.default,
			(typeof props.name === 'undefined') ? props.id : props.name);
		props.wa.form.mandatory(props.wafId, props.id, (typeof props.mandatory === 'undefined') ? false : props.mandatory);
	}, [props, reference]);

	function setValue(event) {
		switch (props.type) {
			case 'checkbox':
				waf.set(props.wafId, props.id, (event.target.checked) ? 1 : 0);
				break;
			default:
			// reference.current.value = event.target.value;
			waf.set(props.wafId, props.id, event.target.value);
		}
		waf[props.wafId].onFormChanged();
		onChange(event);
	}

	function makeElement() {
		var element;
		switch (inputType) {
			case 'text':
				element = makeInput();
				break;
			case 'search':
				element = makeSearch();
				break;
			case 'select':
				element = makeSelect();
				break;
			case 'checkbox':
				element = makeCheckbox();
				break;
			case 'message':
				element = makeMessage();
				break;
			case 'div':
				element = makeDiv();
				break;
			case 'textarea':
				element = makeTextArea();
				break;
			case 'image':
				element = makeImage();
				break;
			case 'posting':
				element = makePosting();
				break;
			default:
				element = makeInput();
		}
		return element;
	}

	function makeCheckbox() {
		const element =
			<div className="form-check mb-2">
				<input className="form-check-input" type="checkbox" ref={reference} disabled={disabled} id={props.id} onChange={setValue} onPaste={(e) => onPaste(e)} />
				<label htmlFor={props.id} className="form-check-label">{props.title}</label>
				{children}
			</div>
		return element;

	}

	function makeDiv() {
		const element =
			<div id={props.id} className="form-div mb-2" ref={reference} >
			</div>
		return element;

	}

	function makeImage() {
		const source = (typeof props.src === 'undefined') ? 'Backend/webapp.php?module=Public&task=Image&file=placeholder.png' : props.src;
		const element =
			<img id={props.id} className="form-img mb-2" src={source} ref={reference} alt={"image-" + props.id} />
		return element;

	}

	function makeInput() {
		const element =
			<div className="webapp-form-item mb-2">
				<label htmlFor={props.id} className="form-label">{props.title}</label>
				<span className="form-element-toolbar">{toolBar}</span>
				<input
					type={inputType}
					ref={reference}
					id={props.id}
					disabled={disabled}
					readOnly={readOnly}
					className={` ${(disabled) ? "form-control-disabled" : "form-control"} ${classes} `}
					maxLength={props.maxLength}
					placeholder={(typeof props.placeholder === 'undefined') ? '' : props.placeholder}
					onChange={setValue} 
					onFocus={(e) => onFocus(e)}
					onPaste={(e) => onPaste(e)}
				/>
				{children}
				{buttons}
			</div>
		return element;

	}

	function makeMessage() {
		const element =
			<div className="webapp-form-item mb-2">
				<input
					type={inputType}
					ref={reference}
					id={props.id}
					disabled={true}
					className={` form-control-message ${classes} `}
				/>
			</div>
		return element;

	}

	function makeSearch() {
		const element =
			<div className="search-element">
				<input type="text" className="form-control rounded-pill" ref={reference} disabled={disabled} id={props.id} onChange={setValue} placeholder="Suchen" />
				{(typeof props.toolBar !== 'undefined') ? props.toolBar : null}
			</div>

		return element;

	}

	function makePosting() {
		const element =
			<div className='element-posting'>
				<i id={props.id} dangerouslySetInnerHTML={{ __html: (typeof props.default == 'undefined') ? '' : props.default }} />
			</div>
		return element;
	}

	function makeSelect() {
		const element = 
		
		<span className="form-select-group">
			<input
				type={inputType}
				ref={reference}
				id={props.id}
				disabled={true}
				className={` form-control form-control-select ${classes} `}
				onChange={setValue} 
			/>
			<i className="bi bi-pencil-fill context-menu-icon"></i>
		</span>
		return element;
	}

	function makeTextArea() {
		const element =
			<div className="webapp-form-item mb-2 ">
				<div className="form-label-group">
					<label htmlFor={props.id} className="form-label">{props.title}</label>
					<span className="form-element-toolbar">{toolBar}</span>
				</div>
				<textarea 
					ref={reference} 
					id={props.id} 
					value={reference.value} 
					readOnly={readOnly} 
					className="form-control" 
					rows={props.rows} 
					onChange={setValue} 
					onFocus={(e) => onFocus(e)}
					onPaste={(e) => onPaste(e)}
					onKeyDown={(e) => 
						{ 
							if (e.key === 'Enter' && !e.shiftKey && false === true) {
								e.preventDefault();
								waf[props.wafId].send();
							}
						}
					}
				/>
			</div>
		return element;

	}

	return (
		<React.Fragment key={"form-element-" + props.id}>
			{makeElement()}
		</React.Fragment>
	);

};
