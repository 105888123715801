import React from 'react';
import Dialog from "Components/Core/Dialog";
import DialogAddLink from './DialogAddLink';
import DialogAddText from './DialogAddText';
import DialogNewImage from './DialogNewImage';
import FormSelectIcon from 'Components/Core/Form/FormSelectIcon';
import FormSelectText from 'Components/Core/Form/FormSelectText';
import DialogSelectText from 'Components/Core/Form/DialogSelectText';
import Module from 'Components/Core/Module';
import NewAnswer from './NewAnswer';
import NewThread from './NewThread';
import PostingDay from './PostingDay';
import PostingMember from './PostingMember';
import PostingList from './PostingList';
import Postings from './Postings';
import PostingSearch from './PostingSearch';
import Tab from 'Components/Core/Tabs';
import 'CSS/Forum/Forum.css'
import 'CSS/Forum/Postings.css'

export default function Forum(props) {

	// IDs der integrierten Dialoge
	const answerId = props.id + '-answer';
	const newThreadId = props.id + '-new-thread';
	const searchId = props.id + '-search';
	const memberId = props.id + '-member';
	const currentId = props.id + '-current';
	const favoritsId = props.id + '-favorits';
	const postingDayId = props.id + '-posting-day';
	const postingMemberId = props.id + '-select-member-postings';
	const selectTopicId = props.id + '-select-topic';
	const dialogIds = [answerId, newThreadId, searchId, currentId, favoritsId, postingDayId];
	const newImageId = props.id + '-new-image';
	const layoutSettingsId = props.id + '-select-layout-settings';
	const closeButton = (props.id === 'forum') ? false : true;

	const $ = window.$;
	const wam = props.wa.module;
	const wad = props.wa.dialog;
	const wah = props.wa.hint;
	const waf = props.wa.form;
	const was = props.wa.system;
	const wau = props.wa.utility;
	const waw = props.wa.webSocket;


	// const [title, setTitle] = React.useState(props.title);
	const title = props.title;

	const [answerTo, setAnswerTo] = React.useState(0);
	const [hideContent, setHideContent] = React.useState(was.forumHideContent);
	const [hideDiscussion, setHideDiscussion] = React.useState(was.forumHideDiscussion);
	const [hideImages, setHideImages] = React.useState(was.forumHideImages);
	const [hideAvatar, setHideAvatar] = React.useState(was.forumHideAvatar);
	const [hideDate, setHideDate] = React.useState(was.forumHideDate);
	const [memberColored, setMemberColored] = React.useState(was.forumMemberColored);
	const [fontBold, setFontBold] = React.useState(was.forumFontBold);
	const [forumScroll, setForumScroll] = React.useState(was.forumScroll);
	const [tabSelected, setTabSelected] = React.useState('postings');

	const renderCount = React.useRef(1);
	const postingCount = React.useRef(null);

	const postingListCurrent = [];										// Hier werden Zeiger auf die benötigten Funktionen der PostingList-Komponenten gespeichert
	const postingListFavorit = [];										// Hier werden Zeiger auf die benötigten Funktionen der PostingList-Komponenten gespeichert

	let updateLastPostingNoRead = (value) => { };
	
	React.useEffect(() => {
		if (renderCount.current === 1) {
			window.webapp.system.webAppLoaded();
		}
	}, []);

	React.useEffect(() => {
		props.wa.system.addWatchKey(props.id, (event) => {
			if (event.keyCode === 27) {
				wad.hideAll();
				//closeDialogs();
			}
		});
		if (renderCount.current === 1) {
			wam[props.id].postings.load();
			// wam[props.id].show();
		}
		// if (!was.isMobileDevice()) waf.focus('new-thread-short', 'new-posting-short');
		renderCount.current++;
	});

	React.useEffect(() => {
		return () => {
			was.delWatchKey(props.id);
		}

	}, [props.forums, props.id, was]);

	function postingsUnreadReset(loadPostings = false) {
		closeDialogs();
		const url = 'Backend/webapp.php?module=Posting&task=PostingsUnreadReset&forumId=' + props.forumId + '&postingCount=' + postingCount.current;
		was.axGet(url, (result) => {
			if (result.data.returncode === 1) {
				was.postingsUnread[props.id] = 0;
				was.postingsLastUnread[props.id] = 0;
				was.loadActiveMember();
				waw.send('read-posting', { "memberid": was.memberId, "module": props.id });
				updateLastPostingNoRead(result.data.content['posting-last-no']);
				was.updatePostingsUnread();
				if (loadPostings) wam[props.id].postings.load();
			}
		});
	}

	/* Funktionen der Dialoge
		Über die Objekte "newThread", "newAnswer", "posting" und "search" stehen die dortigen Funktionen extern zur Verfügung.  
	*/
	if (typeof wam[props.id].newPostings == 'undefined') {
		wam.init(props.id, {
			"init": init,
			"newThread": {},
			"newAnswer": {},
			"postings": {},
			"current": {},
			"topic": {},
			"search": {},
			"tabClicked": setTabSelected,
			"newPostings": false,
			"lastPostingNo": 0,
			"quote": '',
			"quotePosting": 0,
			"getCurrentPostingCount": getCurrentPostingCount,
			"getCurrentTopicId": getCurrentTopicId,
			"moduleType": "forum",
			"title": props.title,
			"scrollTop": scrollTop,
			"debug": debug,
			"closeDialogs": closeDialogs,
			"onDialogClose": onDialogClose,
			"showDialogAnswer": showDialogAnswer,
			"showDialogNewThread": showDialogNewThread,
			"showPostings": showPostings,
			"showThread": showThread,
			"showMemberPostings": showMemberPostings,

		});
	}


	wad.init(answerId);
	wad.init(newThreadId);
	wad.init(searchId);

	// Tab Definition

	const forumTabs = {};
	forumTabs.postings = {
		"title": "Forum",
		"selected": true,
		"classes": "",
		"click": showPostings
	};
	forumTabs.thread = {
		"title": "Dialog",
		"selected": false,
		"classes": "",
		"click": showThread
	};

	function init() {
		if (wam[props.id].newPostings) {
			wam[props.id].postings.load();
			wam[props.id].newPostings = false;
		}
		const conversationalPartner = wau.getConversationalPartner(props.id);
		if (typeof was.postingsUnreadHint[conversationalPartner] !== 'undefined' && wah.isVisible(was.postingsUnreadHint[conversationalPartner])) {
			wah.removeHint(null, was.postingsUnreadHint[conversationalPartner]);
		}
	}

	function debug() {
		const module = props.id + ' - ';
		console.log(module);
	}

	function setLayoutSettingsData() {
		const layoutSettings = [];
		const keys = ['scroll', 'images', 'content', 'discussion', 'avatar', 'date', 'memberColored', 'font'];
		let id = 0;
		(forumScroll === 1) ? addData('Horizontale Ansicht umbrechen') : addData('Horizontale Ansicht scrollen');
		(hideImages === 1) ? addData('Bilder anzeigen') : addData('Bilder ausblenden');
		(hideContent === 1) ? addData('Inhalte anzeigen') : addData('Inhalte ausblenden');
		(hideDiscussion === 1) ? addData('Unterhaltung anzeigen') : addData('Unterhaltung ausblenden');
		(hideAvatar === 1) ? addData('Avatar anzeigen') : addData('Avatar ausblenden');
		(hideDate === 1) ? addData('Datum anzeigen') : addData('Datum ausblenden');
		(memberColored === 1) ? addData('Name des Verfassers schwarz anzeigen') : addData('Name des Verfassers farbig anzeigen');
		(fontBold === 1) ? addData('Normale Schrift') : addData('Fette Schrift');

		return layoutSettings;

		function addData(name) {
			layoutSettings.push({ "id": keys[id], "name": name });
			id++;
		}

	}

	function postingQuote(event, posting, postdate, membername) {
		event.preventDefault();
		const quote = makeQuote(posting, postdate, membername);
		showDialogNewThread(quote, 0);
		// console.log(quote);
	}

	async function copyToClipboard(event, posting, postdate, membername) {
		event.preventDefault();
		try {
			await navigator.clipboard.writeText(makeQuote(posting, postdate, membername));
			event.preventDefault();
			wah.addHint('Der Beitrag wurde in die Zwischenablage kopiert...', true);
		} catch (error) {
			console.error(error.message);
		}
	}

	function makeQuote(posting, postdate, membername) {
		return '' + String.fromCharCode(10) + '[quote]' + membername + ' ' + postdate + String.fromCharCode(10) + posting + String.fromCharCode(10) + '[/quote]' + String.fromCharCode(10)
	}

	function closeDialogs(currentDialog = '') {
		dialogIds.forEach(
			function (dialog) {
				if (dialog !== currentDialog) {
					wad[dialog].hide();
				}
			}
		);
	}

	function getCurrentPostingCount() {
		wam[props.id].currentPostingNo = (wam[props.id].currentPostingNo === 0) ? (wam[props.id].lastPostingNo - 1) : wam[props.id].currentPostingNo;
		return wam[props.id].lastPostingNo - wam[props.id].currentPostingNo;
	}

	function onDialogClose() {
		setTabSelected('postings');
	}

	function showPostings() {
		setTabSelected('postings');
	}

	function showThread() {
		setTabSelected('thread');
	}

	function toggleView() {
		setHideContent(prev => (prev === 1) ? 0 : 1);
		// wam[props.id].postings.render();
	}

	function toggleDiscussion() {
		setHideDiscussion(prev => (prev === 1) ? 0 : 1);
	}

	function toggleImages() {
		setHideImages(prev => (prev === 1) ? 0 : 1);
	}

	function toggleAvatar() {
		setHideAvatar(prev => (prev === 1) ? 0 : 1);
	}

	function toggleScroll() {
		setForumScroll(prev => (prev === 1) ? 0 : 1);
	}

	function toggleDate() {
		setHideDate(prev => (prev === 1) ? 0 : 1);
	}

	function toggleMemberColored() {
		setMemberColored(prev => (prev === 1) ? 0 : 1);
	}

	function toggleFont() {
		setFontBold(prev => (prev === 1) ? 0 : 1);
	}


	function toggleCurrent() {
		closeDialogs(currentId);
		wad[currentId].toggleDisplay();
		if (wad[currentId].isVisible) {
			// wam[props.id].loadCurrentPostings()
			postingListCurrent.load();
			setTabSelected('thread');
		}
		else {
			setTabSelected('postings');
		}
	}

	function toggleFavorits() {
		closeDialogs(favoritsId);
		wad[favoritsId].toggleDisplay();
		if (wad[favoritsId].isVisible) {
			postingListFavorit.load();
			setTabSelected('thread');
		}
		else {
			setTabSelected('postings');
		}

	}

	function toggleMember() {
		closeDialogs(memberId);
		wad[memberId].toggleDisplay();
		if (wad[memberId].isVisible) {
			setTabSelected('thread');
		}
		else {
			setTabSelected('postings');
		}
	}

	function toggleSearch() {
		wad.hideAll(searchId);
		wad[searchId].toggleDisplay(() => { waf.focus(searchId, 'search') });
		setTabSelected('thread');

		/*
		if (wad[searchId].isVisible) {
			setTabSelected('thread');
		}
		else {
			setTabSelected('postings');
		}
		*/
	}

	function togglePostingDay() {
		closeDialogs(postingDayId);
		wad[postingDayId].toggleDisplay();
		if (wad[postingDayId].isVisible) {
			setTabSelected('thread');
		}
		else {
			setTabSelected('postings');
		}

	}

	function showDialogNewThread(quote = '', curPos = -1) {
		closeDialogs();
		// Neuer Thread-Dialog anzeigen
		wad[answerId].hide();
		if (quote !== '') {
			waf.set(newThreadId, 'posting', quote);
			wau.postingBackup(newThreadId, 'posting', quote);
		}
		wad[newThreadId].init();
		wad[newThreadId].show(() => { waf.focus(newThreadId, 'posting') });
		if (curPos > -1) {
			waf.setCursorPos(newThreadId, 'posting', curPos, curPos);
		}
		setTabSelected('postings');
	}

	function showDialogAnswer(reloadPostings, topicId, topicName, postingNo, visible, posting, memberId, memberName, postingDate) {
		closeDialogs();
		wau.postingRestore(answerId, postingNo);
		waf[answerId].addSend['answerto'] = postingNo;
		waf.set(answerId, 'base-posting', posting);
		setAnswerTo(postingNo);
		wad[answerId].memberId = memberId;
		wad[answerId].memberName = memberName;
		wad[answerId].postingNo = postingNo;
		wad[answerId].topicId = topicId;
		wad[answerId].topicName = topicName;
		wad[answerId].postingDate = postingDate;
		wad[answerId].posting = posting;
		wad[answerId].visible = visible;
		// waf.set(props.id + '-form-current-topic', props.id + '-select-set-topic' + '-form', topicName);
		waf.set(props.id + '-form-current-topic', `${props.id}-select-set-topic-form`, topicName);
		wad[answerId].init(postingNo, visible, memberId); // Wird in PostingContextMenu.js ausgeführt
		// wad[answerId].postingRestore();

		wad[answerId].show(() => { 
			waf.focus(answerId, 'posting');
			// wad[answerId].postingRestore();
		});
		wad[answerId].reloadPostings = reloadPostings;
	}

	function showMemberPostings(memberId, memberName) {
		closeDialogs();
		toggleMember();
		wad[postingMemberId + '-dialog'].setValue(memberId, memberName);
	}

	function scrollTop() {
		$('#postings-' + props.id + '-postings').animate({ scrollTop: 0 }, 600);
		wam[props.id].postings.load();
		showPostings();
	}

	function getCurrentTopicId() {
		return waf.getKey('form-current-topic', selectTopicId + '-form');
	}

	function getTopicList() {
		const topicList = [{'id': 0, 'name': 'Alle Themen anzeigen'}].concat(was.topics);
		return topicList;			
	}


	return (

		<React.Fragment>
			<Module id={props.id}
				wa={props.wa}
				title={title}
				display={props.display}
				displayTitle="d-block"
				toolbar={
					(typeof props.memberName !== 'undefined' && props.memberName !== '') ?
						<React.Fragment>
							<span className='module-toolbar'>
								<span className='module-icon-group' onClick={() => was.openProfile(props.memberId, props.memberName, props.memberLevel)}>
									<span className={` bi bi-person-fill module-icon  `} title="Profil anzeigen" >
									</span>
								</span>
							</span>
						</React.Fragment>
						:
						<React.Fragment>
						</React.Fragment>
				}
				titleToolbar={
					(props.id === 'forum')
						?
						<FormSelectText
							id={selectTopicId}
							iconMode={false}
							wafId="form-current-topic"
							name="topicid"
							componentId={wau.getComponentId()}
							moduleId={props.id}
							wa={props.wa}
							default=""
							defaultKey={0}
							title="Alle Themen anzeigen..."
							alwaysShowSelection={false}
							afterSelect={(key, value) => {
								wam[props.id].postings.load();

							}}
							resetIcon={false}

						/>
						:
						null
				}
				closeButton={closeButton}>
				<Tab
					id={props.id + '-tab'}
					wa={props.wa}
					moduleId={props.id}
					tabs={forumTabs}
					tabSelected={tabSelected}
					tabClicked={setTabSelected}
					toolbar={
						<React.Fragment>
						{
							<span
								className={`   `}
								onClick={() => { postingsUnreadReset() }}
								title="Ungelesene Beiträge auf 0 stellen"
							>
								<span
									className={` bi bi-check2-square module-icon new-postings-icon `}
		
								/>
							</span>
		
						}
						<span className='module-icon-group' onClick={e => toggleCurrent()}>
							<span
								className={` bi bi-bell module-icon ${(was.postingsUnread[props.id] > 0) ? "module-icon-clicked" : ""} `}
								title={"Neueste Beiträge " + ((was.postingsUnread[props.id] > 0) ? "(" + was.postingsUnread[props.id] + " ungelesen)" : "")} >
							</span>
						</span>
						<span className={` bi bi-star-fill module-icon `}
							title="Favoriten anzeigen"
							onClick={e => toggleFavorits()}>
						</span>
						<span className={` bi bi-align-top module-icon `}
							title="Zum Anfang der Beiträge scrollen"
							onClick={e => scrollTop()}>
						</span>
						<span className={` bi bi-search module-icon `}
							title="Suchen"
							onClick={e => toggleSearch()}>
						</span>
						<span className={` bi bi-calendar-date module-icon `}
							title="Einen gesamten Tag anzeigen"
							onClick={e => togglePostingDay()}>
						</span>
						<span className={` bi bi-person module-icon `}
							title="Beiträge eines Mitglieds anzeigen"
							onClick={e => toggleMember()}>
						</span>
		
						<FormSelectIcon
							id={layoutSettingsId}
							wafId={layoutSettingsId}
							componentId={wau.getComponentId()}
							moduleId={props.id}
							wa={props.wa}
							classes="bi bi-gear-wide module-icon"
							default=""
							defaultKey={0}
							title='Layout-Einstellungen'
							hiddenInput={true}
							inputIcon='bi-gear-wide'
							onSelect={(key, value) => {
								// ['scroll', 'images', 'content', 'discussion', 'avatar', 'date', 'time']
								switch (key) {
									case 'scroll':
										toggleScroll();
										break;
									case 'images':
										toggleImages();
										break;
									case 'content':
										toggleView();
										break;
									case 'discussion':
										toggleDiscussion();
										break;
									case 'avatar':
										toggleAvatar();
										break;
									case 'date':
										toggleDate();
										break;
									case 'memberColored':
										toggleMemberColored();
										break;
									case 'font':
										toggleFont();
									break;
										default:
										break;
								}
							}}
						/>
					</React.Fragment>
		
					}
					updateForum={props.updateForum}
				/>
				<Postings
					id={'postings-' + props.id}
					threadMode={false}
					forumId={props.forumId}
					wa={props.wa}
					classes={` ${(tabSelected === 'postings') ? "d-block" : "d-none"}`}
					hideContent={hideContent}
					hideDiscussion={hideDiscussion}
					hideImages={hideImages}
					hideAvatar={hideAvatar}
					hideDate={hideDate}
					memberColored={memberColored}
					fontBold={fontBold}
					forumScroll={forumScroll}
					showDialogAnswer={showDialogAnswer}
					moduleId={props.id}
					postingQuote={postingQuote}
					copyToClipboard={copyToClipboard}
					onRendered={(functions) => {
						wam[props.id].postings.load = functions.load;
						wam[props.id].postings.render = functions.makePostings;
						wam[props.id].postings.setSelectDate = functions.setSelectDate;
						wam[props.id].postings.setSelectThread = functions.setSelectThread;
						updateLastPostingNoRead = functions.updateLastPostingNoRead;
						wam[props.id].reload = () => {
							functions.load();
							// closeDialogs();
							showPostings();
						};
					}}
					onLoaded={(postingInfo) => {
						postingCount.current = postingInfo.postingCount;
					}}
				/>
				<Postings
					id={'topic-' + props.id}
					threadMode={true}
					forumId={props.forumId}
					wa={props.wa}
					classes={` ${(tabSelected === 'thread') ? "d-block" : "d-none"} `}
					hideContent={hideContent}
					hideDiscussion={hideDiscussion}
					hideImages={hideImages}
					hideAvatar={hideAvatar}
					hideDate={hideDate}
					memberColored={memberColored}
					fontBold={fontBold}
					forumScroll={forumScroll}
					showDialogAnswer={showDialogAnswer}
					moduleId={props.id}
					postingQuote={postingQuote}
					copyToClipboard={copyToClipboard}
					onRendered={(functions) => {
						wam[props.id].topic.load = functions.load;
						wam[props.id].topic.setSelectDate = functions.setSelectDate;
						wam[props.id].topic.setSelectThread = functions.setSelectThread;
					}}

				/>

				<Dialog id={answerId} wa={props.wa} classes="dialog-new-posting dialog-new-answer " title="Beitrag bearbeiten/antworten" >
					<NewAnswer
						wa={props.wa}
						forumId={props.forumId}
						newPostingFunctions={wam[props.id].newAnswer}
						moduleId={props.id}
						dialogId={answerId}
						answerTo={answerTo}
						postingQuote={postingQuote}
						copyToClipboard={copyToClipboard}
					/>
				</Dialog>

				<Dialog id={newThreadId} wa={props.wa} classes="dialog-new-posting dialog-new-thread" title="Neues Thema" >
					<NewThread
						wa={props.wa}
						forumId={props.forumId}
						newPostingFunctions={wam[props.id].newThread}
						moduleId={props.id}
						dialogId={newThreadId}
						answerTo={0}
						postingQuote={postingQuote}
					/>
				</Dialog>
				<DialogNewImage
					id={newImageId}
					wa={props.wa}
					moduleId={props.id}
					dialogId={newImageId}
				/>
				<Dialog id={currentId} wa={props.wa} classes="dialog-postings-list " title="Neueste Beiträge" handleClose={showPostings}>
					<PostingList
						id="current-postings"
						wa={props.wa}
						forumId={props.forumId}
						moduleId={props.id}
						dialogId={currentId}
						module="Posting"
						task="PostingCurrent"
						postingQuote={postingQuote}
						copyToClipboard={copyToClipboard}
						showDialogAnswer={showDialogAnswer}
						onRendered={(functions) => {
							postingListCurrent.load = functions.searchPostings;
						}}

					/>
				</Dialog>
				<Dialog id={favoritsId} wa={props.wa} classes="dialog-postings-list " title="Favoriten" handleClose={showPostings}>
					<PostingList
						id={favoritsId + "-listing"}
						wa={props.wa}
						forumId={props.forumId}
						moduleId={props.id}
						dialogId={favoritsId}
						module="Posting"
						task="PostingFavorits"
						postingQuote={postingQuote}
						copyToClipboard={copyToClipboard}
						showDialogAnswer={showDialogAnswer}
						onRendered={(functions) => {
							postingListFavorit.load = functions.searchPostings;
						}}

					/>
				</Dialog>
				<Dialog id={searchId} wa={props.wa} classes="dialog-search-posting " title="Suchen" >
					<PostingSearch
						wa={props.wa}
						forumId={props.forumId}
						moduleId={props.id}
						dialogId={searchId}
						copyToClipboard={copyToClipboard}
						showDialogAnswer={showDialogAnswer}
					/>
				</Dialog>

				<PostingMember
					wa={props.wa}
					forumId={props.forumId}
					moduleId={props.id}
					postingMemberId={postingMemberId}
					toggleMember={toggleMember}
					handleClose={showPostings}
					showDialogAnswer={showDialogAnswer}
					copyToClipboard={copyToClipboard}
				/>
				<Dialog id={postingDayId} wa={props.wa} classes="dialog-posting-day " title="Gesamten Tag anzeigen" handleClose={showPostings}>
					<PostingDay
						wa={props.wa}
						forumId={props.forumId}
						moduleId={props.id}
						dialogId={postingDayId}
					/>
				</Dialog>
				<DialogAddLink id={props.id + '-add-link'} wa={props.wa} title="Link einfügen" />
				<DialogAddText id={props.id + '-add-list'} wa={props.wa} tag="ul" type="textarea" title="Liste einfügen" />
				<DialogAddText id={props.id + '-add-text-b'} wa={props.wa} tag="b" title="Fett formatierter Text" />
				<DialogAddText id={props.id + '-add-text-i'} wa={props.wa} tag="i" title="Kursiv formatierter Text" />
				<DialogAddText id={props.id + '-add-text-u'} wa={props.wa} tag="u" title="Unterstrichener Text" />
				<DialogSelectText
					id={layoutSettingsId}
					moduleId={props.id}
					wa={props.wa}
					setData={setLayoutSettingsData}
					classes="dialog-select-layout-settings"
					title="Layout-Optionen"
				/>

				{(props.id === 'forum') ? <DialogSelectText id={selectTopicId} moduleId={props.id} wa={props.wa} data={getTopicList()} title="Nach Thema filtern" /> : null}

			</Module>
		</React.Fragment>
	)
};
