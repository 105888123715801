import React from 'react';
import logo from '../../IMG/logo-panikboard.gif';
import 'CSS/Member/Login.css';
import Form from 'Components/Core/Form/Form';
import FormElement from '../Core/Form/FormElement';
import Dialog from 'Components/Core/Dialog';

export default function Login(props) {

	const divReference = React.useRef(null);
	const [error, setError] = React.useState(false);
	const wafId = props.id;
	const wad = props.wa.dialog;
	const waf = props.wa.form;
	const wam = props.wa.module;
	const was = props.wa.system;
	const waw = props.wa.webSocket;

	const privateMessage = (was.privateMessage !== '') ? <div>{was.privateMessage}</div> : null;
	const loginMessage = <span>Bitte melden Sie sich an.</span>;

	wam.init(props.id);
	waf.init(wafId, {
		"onAfterSend": onAfterSend,
	});

	React.useEffect(() => {
		wad[props.id].show(() => {waf.focus('login', 'membername');});
	});

	function onAfterSend(data) {
		if (data.content['login'] === 1) {
			waw.send('login', { "module": props.moduleId, "member": waf.get(wafId, 'membername'), "memberlevel": data.content['memberlevel'] });
			if (waf.get(wafId, 'autologin')===1) {
				localStorage.setItem('token', data.content['token']);
			}
			else {
				localStorage.removeItem('token');
			}
			setTimeout(window.location.reload(), 1000);
		}
		else {
			waf[wafId].enableSendButton(waf.checkChanged(wafId));
			setError(true);
		}
	}

	function handleError() {
		setError(true);
	}

	return (
		<div id="login-frame" ref={divReference} >
			<Dialog id={props.id} wa={props.wa} classes="login" title="Login" >

				<div id="login" className={``}>
					<div >
						<img alt="Logo" src={logo} />
						<Form wa={props.wa}
							wafId={wafId}
							module="Public"
							sendTask="Login"
							focusElement="membername"
							classes="settings-form"
							cancelButton={false}
							sendButtonTitle="Login"
							enableSendButton={true}
							handleError={handleError}
						>
							<FormElement wa={props.wa} wafId={props.id} id="membername" title="Name" fieldValue="" />
							<FormElement wa={props.wa} wafId={props.id} type="password" id="password" title="Password" fieldValue="" />
							<FormElement type="checkbox" wa={props.wa} wafId={props.id} id="autologin" title="Automatisch anmelden"  />
							<div className={`form-group ${(error === true) ? 'd-block' : 'd-none'}`} >
								<label id="login-error" >Login mit diesen Daten nicht möglich!</label>
							</div>
							<div className={`form-group ${(error === false) ? 'd-block' : 'd-none'}`} >
								<span className='message'>
									{loginMessage}
								</span>
							</div>
							<span className='message'>
									{privateMessage}
							</span>
						</Form>
					</div>
				</div>
			</Dialog>
		</div>
	);

}
