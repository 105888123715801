import React from "react";

export default function WebappLayout(props) {

	const wa = props.wa;
	const wal = wa.layout;
	const style = {};
	wal.style = style;

	const renderCount = React.useRef(1);
	const [theme, setTheme] = React.useState('light');


	React.useEffect(() => {
		if (renderCount.current === 1) {
			props.childRendered(props.id, {"setTheme" : setTheme});
		}
		renderCount.current++;
	}, [props, setTheme]);

	if (theme === "light") {
		// Global

		style["--main-bg-color"] = "#5f8c6c";
		style["--main-color"] = "#fff";
		style["--hint-bg-color"] = "#f8f7f5";
		style["--hint-color"] = "#5f8c6c";
		style["--login-color"] = "#000";
		style["--login-bg-color"] = "#F3F2EF";
		style["--content-color"] = "#000";
		style["--content-color-error"] = "#dc3545";
		style["--content-color-hint"] = "#43624c";
		style["--content-bg-color"] = "#5f8c6c";
		style["--dialog-color"] = "#5f8c6c";
		style["--dialog-title-color"] = "#444";
		style["--dialog-bg-color"] = "#fff";
		style["--dialog-new-posting-color"] = "#5f8c6c";
		style["--dialog-new-posting-title-color"] = "#444";
		style["--dialog-new-posting-bg-color"] = "#fff";
		style["--shadow-color"] = "#888";
		style["--icon-color"] = "#5f8c6c";
		style["--icon-color-clicked"] = "#c11734";
		style["--tab-color"] = "#121212";
		style["--tab-selected-color"] = "#efefef";
		style["--grid-title-border-color"] = "#fff";
		style["--listing-color"] = "#444";
		style["--listing-bg-color"] = "#fefefe";
		style["--listing-bg-color-hover"] = "#aaa";
		style["--listing-title-color"] = "#fff";
		style["--listing-title-bg-color"] = "#5f8c6c";
		style["--listing-nav-color"] = "#dfdfdf";
		style["--listing-nav-bg-color"] = "#475052";


		// Forum
		style["--color-member"] = "#111";
	}
	else {
		// Global
		style["--main-bg-color"] = "#5f8c6c";
		style["--main-color"] = "#fff";
		style["--hint-bg-color"] = "#E7E4DE";
		style["--hint-color"] = "#000";
		style["--login-color"] = "#5f8c6c";
		style["--login-bg-color"] = "#F3F2EF";
		style["--content-color"] = "#fff";
		style["--content-color-error"] = "#dc3545";
		style["--content-color-hint"] = "#43624c";
		style["--content-bg-color"] = "#5f8c6c";
		style["--dialog-color"] = "#bbb";
		style["--dialog-title-color"] = "#fff";
		style["--dialog-bg-color"] = "#444";
		style["--dialog-new-posting-color"] = "#5f8c6c";
		style["--dialog-new-posting-title-color"] = "#444";
		style["--dialog-new-posting-bg-color"] = "#ddd";
		style["--icon-color"] = "#5f8c6c";
		style["--icon-color-clicked"] = "#c11734";
		style["--tab-color"] = "#efefef";
		style["--tab-selected-bg-color"] = "#121212";
		style["--grid-title-border-color"] = "#efefef";
		style["--listing-color"] = "#444";
		style["--listing-bg-color"] = "#5f8c6c";
		style["--listing-bg-color-hover"] = "#dfdfdf";
		style["--listing-nav-color"] = "#dfdfdf";
		style["--listing-nav-bg-color"] = "#475052";

		// Forum
		style["--color-member"] = "#eee";
	}
	style["--mobile-width"] = "1024px";


	return (
		<React.Fragment />
	);

}

